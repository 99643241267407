import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiButton, EuiSpacer } from '@elastic/eui';
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemAlarmPerm';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud",
  "path": "/Web_User_Interface/1440p_Series/Cloud/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Cloud",
  "image": "./WebUI_1440p_SearchThumb_Cloud_Introduction.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Cloud_Introduction.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='INSTAR Cloud' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='IP Camera Cloud Storage' image='/images/Search/QI_SearchThumb_WebUI_1440p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Cloud/' locationFR='/fr/Web_User_Interface/1440p_Series/Cloud/' crumbLabel="INSTAR Cloud" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e147a6feae75bd70650c20dc1890016/024d6/1440p_Settings_Cloud.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACNElEQVQoz12RXWvTYBiG+zPEDXYg7dIkLWuRrd9p0vS7SZO8SbquGzqQ4dzm10AnU6Yi6IkejW0w1INB/UIEYf69S9I5rB5cvPDycHE/zx1LpVKoaoqklCBTMOk8OKVx9xRz+wRt85jK7SO0/4j+Gjsn2Lvv6YbrzF69wszMDHNzc8QURUFRVKREnOvlJqODMYOnY5afjQn2/8WfekcHn7jx4jMNZxVFSqKqaWRZJSbLMhHJZJLMQhphtxl4FqFnIewWTreB24to4lkXuN0GoWczWg7RNYNKuYZerVPIVy4TKhNhNpslCAcMhysMlocIP8D1BJ7wsfsO3Z51QbdHz/FxhrdoNVpUNZ1K2SC3VPwrlCSJTCZDEPiMVoaEYYDnebiuixCCer1BoVC8IJ8nt7iIoVcRbhPTqE5S5pZK/wqz2QxW36NlCzq2mBL6BMIlcC08x6btBTQ9n57wGYZ1TENDKxsU8qXplSXSC1m21vq82bI42LDwhYvrenhuH7G6gdjYx13fZdty2OtYbFp9XNukpmnUDZ1ioTAllCSUdIZHNzucPW5zdL9NIJzJDftWl/7aDqO9Y4YP3/HSCTi0PZ67gnW/h1mtTIT5XJFYVMZl07KcJC6pzF5TSGWLGHq0jkmv3eHVk3t8PHzNj5/f+XX+lS9nHzj/NubtnTXatQpa5Y8wut1001FSOTmPNJ8gkZgnHk+wkFbRS3lKxSJaVcPUStTKJeo1ncVsCkVKEI9Hs3F+AxTBdr5jWYJ+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e147a6feae75bd70650c20dc1890016/e4706/1440p_Settings_Cloud.avif 230w", "/en/static/9e147a6feae75bd70650c20dc1890016/d1af7/1440p_Settings_Cloud.avif 460w", "/en/static/9e147a6feae75bd70650c20dc1890016/7f308/1440p_Settings_Cloud.avif 920w", "/en/static/9e147a6feae75bd70650c20dc1890016/7733b/1440p_Settings_Cloud.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e147a6feae75bd70650c20dc1890016/a0b58/1440p_Settings_Cloud.webp 230w", "/en/static/9e147a6feae75bd70650c20dc1890016/bc10c/1440p_Settings_Cloud.webp 460w", "/en/static/9e147a6feae75bd70650c20dc1890016/966d8/1440p_Settings_Cloud.webp 920w", "/en/static/9e147a6feae75bd70650c20dc1890016/85db8/1440p_Settings_Cloud.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e147a6feae75bd70650c20dc1890016/81c8e/1440p_Settings_Cloud.png 230w", "/en/static/9e147a6feae75bd70650c20dc1890016/08a84/1440p_Settings_Cloud.png 460w", "/en/static/9e147a6feae75bd70650c20dc1890016/c0255/1440p_Settings_Cloud.png 920w", "/en/static/9e147a6feae75bd70650c20dc1890016/024d6/1440p_Settings_Cloud.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e147a6feae75bd70650c20dc1890016/c0255/1440p_Settings_Cloud.png",
              "alt": "Web User Interface - 1440p Series - Cloud",
              "title": "Web User Interface - 1440p Series - Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Link to="/Motion_Detection/INSTAR_Cloud/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Read More</EuiButton></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      